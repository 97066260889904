import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';

import { Section, Text } from '../../components/Core';
import teamImage from '../../assets/image/jpeg/ourteam.jpg';
import { device } from '../../utils';

const SectionStyled = styled(Section)`
    background-image: url(${teamImage});
    background-size: cover;
    background-position: bottom left;

    @media ${device.lg} {
        background-position: center;
    }
`;

const Hero = ({ title, children, ...rest }) => {
    return (
        <>
            <SectionStyled
                pt={['120px!important', null, '250px!important']}
                pb={['120px!important', null, '250px!important']}
                {...rest}
            >
                <Container>
                    <Row>
                        <Col>
                            <div>
                                <Text>{children}</Text>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </SectionStyled>
        </>
    );
};
export default Hero;
