import React from "react";

import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/our-team/Hero";
import Team from "../sections/about/Team";

const About = () => {
  return (
    <>
      <PageWrapper footerDark>
        <Hero title="Our Team"></Hero>
        <Team />
      </PageWrapper>
    </>
  );
};
export default About;
